import { API } from 'aws-amplify';
import { mutate } from 'swr';
import useAuth from '../../../../store/auth/hooks/useAuth';
import { useStateValue } from '../index';
import {
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  formReset,
} from '../actions';
import { FormStatus } from '../constants';
import useAlert from '../../../../store/alert/hooks/useAlert';
import useLocal from '../../../../store/local/hooks/useLocal';

const usePost = () => {
  const { user, jwt } = useAuth();
  const { text } = useLocal();
  const { state, dispatch } = useStateValue();
  const { showFailureAlert } = useAlert();

  const publish = () => {
    const apiName = 'AdminQueries';
    const path = '/publishContent';

    const headers: any = {};
    headers['Content-Type'] = 'application/json';
    headers.Authorization = jwt;

    const payload: any = {};
    payload.headers = headers;
    payload.body = { email: user.attributes.email };

    const refetch = () => mutate('deployments');

    const handle200 = (response: any) => {
      // eslint-disable-next-line
      console.log(response);
      refetch();
      dispatch(formSubmittedWithSuccess());
    };

    const handle500 = (err: any) => {
      dispatch(formSubmittedWithError());
      if (err?.response?.status === 503) {
        showFailureAlert(text.alerts.serviceUnavailableErrorMessage);
      } else {
        showFailureAlert();
      }
    };

    dispatch(formSubmitted());

    API
      .post(apiName, path, payload)
      .then((res: any) => handle200(res))
      .catch((e) => handle500(e));
  };

  const resetFormState = () => dispatch(formReset());

  return {
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithSuccess: state.formState === FormStatus.SUBMITTED_SUCCESS,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    publish,
    resetFormState,
  };
};

export default usePost;
